





























import { Component, Prop, Watch } from 'vue-property-decorator'
import { Filter } from '@movecloser/front-core'
import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig } from '../../../../../contexts'
import { FiltersHandlerMixin } from '../../../../../support/mixins/FiltersHandler.mixin.vue'

import FilterPopover from '../../Filters/partials/FilterPopover/FilterPopover.vue'

import ControlItem from './ControlItem.vue'
import { ControlItemConfig, ListDisplayControlsProps } from '../ListDisplayControls.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ClosableControls>({
  name: 'ClosableControls',
  components: { ControlItem, FilterPopover }
})
export class ClosableControls extends FiltersHandlerMixin {
  @Prop({ required: true, type: Object })
  public icons!: Record<string, string>

  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: true, default: null, type: Array })
  public currentFilter!: Filter[]

  @Prop({ required: false, type: Number })
  public total!: ListDisplayControlsProps['total'] | null

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)

  /**
   * Defines whether filters are visible
   */
  public visible: boolean = true

  public get areFiltersVisible (): boolean {
    return this.visible
  }

  @Watch('filters')
  private update () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }

  /**
   * Toggles filters visibility
   */
  public toggleVisibility (): void {
    this.visible = !this.visible
    this.$emit('setVisibility', this.visible)
  }

  /**
   * Update control
   * @param value
   */
  public updateQuery (value: ControlItemConfig) {
    if (this.filters) {
      this.filters[value.queryParam] = value.filter
    }

    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }
}

export default ClosableControls
