
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FILTERS_WRAPPER_COMPONENT_KEY = 'FiltersWrapperComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface FiltersWrapperConfig {
  filterIcon: string
  shouldAnimate: boolean
  shouldImplementMobileHeading: boolean
  shouldUseMobileFilters: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FILTERS_WRAPPER_COMPONENT_CONFIG_MAP: FiltersWrapperConfig = {
  filterIcon: 'FilterIcon',
  shouldAnimate: false,
  shouldImplementMobileHeading: false,
  shouldUseMobileFilters: true
}
