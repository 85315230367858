










































import { Component, Prop, Watch } from 'vue-property-decorator'
import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig } from '../../../../../contexts'
import { FiltersHandlerMixin } from '../../../../../support/mixins/FiltersHandler.mixin.vue'

import FilterPopover from '../../Filters/partials/FilterPopover/FilterPopover.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<PopoverControls>({
  name: 'PopoverControls',
  components: { FilterPopover }
})
export class PopoverControls extends FiltersHandlerMixin {
  @Prop({ required: true, type: Object })
  public icons!: Record<string, string>

  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)

  public getLabel (paramConfig: FilterParamConfig) {
    if (!this.filters) {
      return ''
    }

    const value = this.filters[paramConfig.queryParam] as string

    return paramConfig.options[value] ?? ''
  }

  @Watch('filters')
  private update () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }

  private onChange (queryParam: string, value: string) {
    this.filters = this.updateFilter(this.filters, queryParam, value)
  }
}

export default PopoverControls
