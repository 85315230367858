















import { AsyncComponent, VueConstructor } from 'vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig } from '../../../../contexts'
import { FiltersHandlerMixin, StructureConfigurable } from '../../../../support/mixins'
import { logger } from '../../../../support'

import {
  FilterLayout,
  FILTERS_COMPONENT_TYPE_KEY,
  FiltersComponentConfig,
  filtersComponentConfigMap
} from '../Filters/Filters.config'

import ClosableControls from './partials/ClosableControls.vue'
import { FiltersWrapperProps } from '../FiltersWrapper/FiltersWrapper.contracts'
import { listDisplayControlsComponentsRegistry } from './ListDisplayControls.config'
import PopoverControls from './partials/PopoverControls.vue'
import { Location } from 'vue-router/types/router'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ListDisplayControls>({
  name: 'ListDisplayControls',
  components: {
    ClosableControls, PopoverControls
  },
  created () {
    this.config = {
      ...this.getComponentConfig(FILTERS_COMPONENT_TYPE_KEY, filtersComponentConfigMap),
      ...this.filtersConfig
    }
  },
  beforeMount (): void {
    this.initCurrentFilters()
  }
})
export class ListDisplayControls extends Mixins(FiltersHandlerMixin, StructureConfigurable) {
  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: false, type: Number })
  public total?: FiltersWrapperProps['total'] | null

  @Prop({ required: false, type: Object })
  public filtersConfig?: FiltersComponentConfig

  protected config!: FiltersComponentConfig

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)

  public current: (string | number | boolean | undefined)[] = []

  public get currentFilter () {
    return this.current
  }

  public get icons (): string {
    return this.getConfigProperty<string>('icons')
  }

  public get pageComponentType (): VueConstructor | AsyncComponent {
    if (!(this.config.layout in listDisplayControlsComponentsRegistry)) {
      logger(
        '[Filters.layout] has not been defined in App configuration! Default value might not be suitable for current project.',
        'warn'
      )
      return listDisplayControlsComponentsRegistry[FilterLayout.Popover]
    }

    return listDisplayControlsComponentsRegistry[this.config.layout]
  }

  public getLabel (paramConfig: FilterParamConfig) {
    if (!this.filters) {
      return ''
    }

    const value = this.filters[paramConfig.queryParam] as string

    return paramConfig.options[value] ?? ''
  }

  @Watch('filters')
  private update () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }

  protected initCurrentFilters (): void {
    const currentFilters = []

    for (const param of Object.values(this.params)) {
      for (const [k, v] of Object.entries(this.query)) {
        if (k === param.queryParam) {
          currentFilters.push(v)
        }
      }
    }

    this.current = currentFilters
  }

  public updateQuery (newQuery: Location['query'], newFilters: FiltersConfig) {
    this.$emit('setQuery', newQuery, newFilters)
  }
}

export default ListDisplayControls
